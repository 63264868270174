import React, { useState } from 'react';
import './ContactUsPage.css';
import Header from '../components/Header'; // Assuming the path to your Header component
import Footer from '../components/Footer'; // Assuming the path to your Footer component

const ContactUsPage = () => {
    return (
        <>
            <Header />
            <div className="contact-wrapper">
                <div className="contact-header-section">
                    <h1 className="contact-heading">Get in Touch with Us</h1>
                    <p className="contact-subheading">
                        We are here to assist you with any queries or concerns. Reach out to us at any of our offices or drop us an email.
                    </p>
                </div>

                <div className="contact-container">
                    <div className="address-card">
                        <h2 className="address-title">Corporate Office</h2>
                        <p>Live Well, Sukumara Homestay, Coastal Road,</p>
                        <p>Valapad Beach, Kothakulam - 680567,</p>
                        <p>Thrissur, Kerala</p>
                    </div>

                    <div className="address-card">
                        <h2 className="address-title">Regional Office</h2>
                        <p>Live Well, 31/27-1, Chakoth Ln,</p>
                        <p>Punkunnam, Thrissur, Kerala - 680002</p>
                    </div>
                </div>

                <div className="query-section">
                    <p className="query-text">For more queries, reach us out</p>
                    <a href="mailto:mail.pachila@gmail.com" className="email-button">
                        Click Here
                    </a>
                </div>

            </div>
            <Footer /> {/* Assuming you have a Footer component */}
        </>
    );
};

export default ContactUsPage;
