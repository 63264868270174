import React from 'react';
import './AboutUsPage.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const AboutUsPage = () => {
    return (
        <>
            <Header />
            <div className="about-us">
                <section className="hero">
                    <div className="hero-content">
                        <h1 className='aco'>About Pachila</h1>
                        <p className="tagline aco">Cultivating connections between people and the planet.</p>
                    </div>
                </section>
                <div className="content">
                    <div className="card">
                        <h2>Our Story</h2>
                        <p>
                            At Pachila, we believe in the power of storytelling to connect hearts and minds. 
                            By sharing stories that resonate with our humanity, we foster a deeper understanding 
                            and compassion for ourselves, each other, and our planet.
                        </p>
                    </div>
                    <div className="card">
                        <h2>Our Mission</h2>
                        <p>
                            Our connection to the Earth is at the core of what we do. We invite you to create your personal oasis 
                            through gardening and sustainable living while contributing to a greener world.
                        </p>
                    </div>
                    <div className="card">
                        <h2>Join Us</h2>
                        <p>
                            Let’s nurture a future where compassion for humanity and the planet coexist. 
                            We are incredibly grateful to all who have joined us on this journey toward a sustainable future.
                        </p>
                        <a href="/contact-us" className="contact-button">Contact Us</a>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutUsPage;
