import React, { useEffect, useState } from 'react';
import './WelcomePage.css';
import livewell from '../assets/livewell2.jpg';
import shop from '../assets/shop2.jpg';
import stories from '../assets/stories.png';
import exclusive from '../assets/exclusive22.jpg';
import axios from 'axios';
import Slider from 'react-slick';

const YOUTUBE_API_KEY = 'AIzaSyA3zZhJeyXx_WOVBGhXaHkkHHov5qyBiEs';
const SHOPIFY_ACCESS_TOKEN = 'c3dc7231670ccec1aaa223cbfa24d3bd';
const SHOPIFY_STORE_URL = 'https://store.pachila.in/api/2021-07/graphql.json';

const WelcomePage = () => {
    const videoIds = ['5Wex7pPQ3D8', '_wVY9dedK_4', 'R2HtxHppbqU'];
    const [trendingVideos, setTrendingVideos] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchVideoDetails = async () => {
            try {
                const response = await axios.get(
                    `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoIds.join(',')}&key=${YOUTUBE_API_KEY}`
                );
                const videosData = response.data.items.map((item) => ({
                    id: item.id,
                    url: `https://www.youtube.com/watch?v=${item.id}`,
                    thumbnail: item.snippet.thumbnails.high.url,
                    title: item.snippet.title.substring(0, 50) + '...',
                }));
                setTrendingVideos(videosData);
            } catch (error) {
                console.error('Error fetching video details:', error);
            }
        };

        const fetchProducts = async () => {
            const productHandles = [
                "manjaly-banana-powder",
                "manjaly-jack-fruit-seed-powder",
                "black-pepper-powder",
                "manjaly-arrow-root-powder",
                "manjaly-kannankaya-powder",
            ];
        
            try {
                const productsData = [];
        
                for (const handle of productHandles) {
                    const response = await axios.post(
                        SHOPIFY_STORE_URL,
                        {
                            query: `
                                {
                                    productByHandle(handle: "${handle}") {
                                        id
                                        title
                                        handle
                                        images(first: 1) {
                                            edges {
                                                node {
                                                    src
                                                }
                                            }
                                        }
                                        priceRange {
                                            minVariantPrice {
                                                amount
                                            }
                                        }
                                    }
                                }
                            `,
                        },
                        {
                            headers: {
                                'X-Shopify-Storefront-Access-Token': SHOPIFY_ACCESS_TOKEN,
                                'Content-Type': 'application/json',
                            },
                        }
                    );
        
                    const product = response.data.data.productByHandle;
                    if (product) {
                        productsData.push({
                            id: product.id,
                            title: product.title,
                            url: `https://store.pachila.in/products/${product.handle}`,
                            imageUrl: product.images.edges[0]?.node.src || '',
                            price: product.priceRange.minVariantPrice.amount,
                        });
                    }
                }
        
                setProducts(productsData);
            } catch (error) {
                console.error('Error fetching specific products:', error);
            }
        };
        

        fetchVideoDetails();
        fetchProducts();
    }, []);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="welcome-container">
            {/* Services Section */}
            <section className="services-section">
                <h2>Our Core Services</h2>
                <div className="services-grid">
                    <div className="service-card" style={{ backgroundImage: `url(${shop})` }}>
                        <div className="card-overlay">
                            <a href="https://store.pachila.in" className="more-info">Shop Now</a>
                        </div>
                    </div>
                    <div className="service-card" style={{ backgroundImage: `url(${livewell})` }}>
                        <div className="card-overlay">
                            <a href="/live-well" className="more-info">Live Well</a>
                        </div>
                    </div>
                    <div className="service-card" style={{ backgroundImage: `url(${stories})` }}>
                        <div className="card-overlay">
                            <a href="/stories" className="more-info">Read Stories</a>
                        </div>
                    </div>
                    <div className="service-card" style={{ backgroundImage: `url(${exclusive})` }}>
                        <div className="card-overlay">
                            <a href="/exclusive" className="more-info">Exclusives</a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Appointment Banner */}
            <section className="appointment-banner">
                <h3>Ready to Begin Your Wellness Journey?</h3>
                <a href="/live-well#book-appointment" className="appointment-button">Book an Appointment</a>
            </section>

            {/* Product Slider Section */}
            <section className="products-section">
                <h2>Featured Products</h2>
                <Slider {...sliderSettings}>
                    {products.map((product) => (
                        <div className="product-card" key={product.id}>
                            <a href={product.url} target="_blank" rel="noopener noreferrer">
                                <img src={product.imageUrl} alt={product.title} />
                                <h4>{product.title}</h4>
                                <p>₹{product.price}</p>
                            </a>
                        </div>
                    ))}
                </Slider>
            </section>

            {/* Trending Videos Section */}
            <section className="videos-section">
                <h2>Trending Wellness Videos</h2>
                <div className="video-gallery">
                    {trendingVideos.map((video) => (
                        <div className="video-card" key={video.id}>
                            <a href={video.url} target="_blank" rel="noopener noreferrer">
                                <img src={video.thumbnail} alt={video.title} />
                                <h4>{video.title}</h4>
                            </a>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default WelcomePage;
