import React from 'react';
import './LiveWell.css'; // Updated custom styling for LiveWell page
import liveWellLogo from '../assets/live-welll.png'; // Ensure the logo is high-resolution
import Header from '../components/Header';
import Footer from '../components/Footer';

const LiveWell = () => {
  return (
    <>
      <Header /> {/* Include Header component */}
      <main className="live-well">
        {/* Hero Section */}
        <section className="hero">
          <div className="hero-content">
            <img src={liveWellLogo} alt="Live Well Logo" className="hero-logo" />
            <h1 className='aco'>Live Well</h1>
            <p className='aco'>Embrace Nature, Embrace Health</p>
            <a href="#consultations" className="cta-button">Book a Consultation</a>
          </div>
        </section>

        {/* About Us Section */}
        <section className="about-us">
          <div className="container">
            <h2>About LiveWell</h2>
            <p>
              At LiveWell, we are dedicated to providing holistic wellness solutions rooted in nature.
              Our expert practitioners combine traditional healing methods like Ayurveda, Homeopathy, and
              Siddha with modern ecological principles to offer personalized care. Embark on a journey
              towards a healthier, happier you with LiveWell.
            </p>
          </div>
        </section>

        {/* Services Section */}
        <section className="services">
          <div className="container">
            <h2>Our Services</h2>
            <div className="service-cards">
              <div className="card">
                <h3>Personalized Consultations</h3>
                <p>Tailored health plans designed to meet your unique needs.</p>
              </div>
              <div className="card">
                <h3>Nourishing Diet Plans</h3>
                <p>Organic, locally sourced foods to support your healing journey.</p>
              </div>
              <div className="card">
                <h3>Holistic Therapies</h3>
                <p>Ayurveda, yoga, and meditation to restore balance and harmony.</p>
              </div>
              <div className="card">
                <h3>Lifestyle Modifications</h3>
                <p>Adopt sustainable habits for long-term well-being.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Consultations Section */}
        <section id="consultations" className="consultations">
          <div className="container">
            <h2>Consultations</h2>
            <ul className="consultation-list">
              <li>
                <strong>General Consultation</strong> (Rs. 500): 30-minute session for a personalized plan.
              </li>
              <li>
                <strong>Diabetes Consultation</strong> (Rs. 500): Comprehensive care focusing on ecological well-being.
              </li>
              <li>
                <strong>Women's Health</strong> (Rs. 500): Addressing PCOS, menstrual issues, and more with natural remedies.
              </li>
              <li>
                <strong>Cancer Support</strong> (Rs. 500): Ecology-based care during and after treatment.
              </li>
              <li>
                <strong>Skin Health</strong> (Rs. 500): Solutions for allergies, autoimmune disorders, and more.
              </li>
            </ul>
            <a href="https://forms.gle/3k6rTghbnXq98HcL7" className="cta-button">Book Now</a>
          </div>
        </section>

        {/* Contact Section */}
        <section className="contact">
          <div className="container">
            <h2>Contact Us</h2>
            <p>Consultation Hours: 9:00 AM – 9:00 PM, 7 days a week</p>
            <p>
              For support: <a href="mailto:support@livewell.com">support@livewell.com</a>
            </p>
          </div>
        </section>
      </main>
      <Footer /> {/* Include Footer component */}
    </>
  );
};

export default LiveWell;
