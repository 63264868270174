import React, { useState } from 'react';
import './Header.css';
import logo from '../assets/pachila.png';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="site-header">
            <div className="header-container">
                <a href="/"> {/* Wrapping the logo in a link to home */}
                    <img className='logo-img' src={logo} alt="Pachila Logo" />
                </a>
                <nav className={`main-nav ${isOpen ? 'open' : ''}`}>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li className='appointment-button'><a href="https://store.pachila.in">Shop</a></li>
                        <li><a href="/about-us">About</a></li>
                        <li><a href="/contact-us">Contact</a></li>
                        <li className="mobile-appointment-button">
                            <a href="https://forms.gle/3k6rTghbnXq98HcL7" target="_blank" rel="noopener noreferrer">
                                Book an Appointment
                            </a>
                        </li>
                    </ul>
                </nav>
                <button className="appointment-button">
                    <a href="https://forms.gle/3k6rTghbnXq98HcL7" target="_blank" rel="noopener noreferrer">
                        Book an Appointment
                    </a>
                </button>
                <button className="hamburger" onClick={toggleMenu}>
                    {isOpen ? '✖' : '☰'}
                </button>
            </div>
        </header>
    );
};

export default Header;
