import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import LiveWell from './pages/LiveWell'; // Import LiveWell component
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import routing components
import reportWebVitals from './reportWebVitals';
import Stories from './pages/Stories';
import Exclusive from './pages/Exclusive';
import AboutUsPage from './pages/AboutUs';
import ContactUsPage from './pages/ContactUs';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {/* Define the routes here */}
        <Route path="/" element={<App />} /> {/* Root path will load the App component */}
        <Route path="/live-well" element={<LiveWell />} /> {/* /live-well path will load the LiveWell component */}
        <Route path="/stories" element={<Stories />} /> {/* /Stories path will load the LiveWell component */}
        <Route path="/exclusive" element={<Exclusive />} /> {/* /Stories path will load the LiveWell component */}
        <Route path="/about-us" element={<AboutUsPage />} /> {/* /Stories path will load the LiveWell component */}
        <Route path="/contact-us" element={<ContactUsPage />} /> {/* /Stories path will load the LiveWell component */}
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
