import axios from 'axios';

const YOUTUBE_API_KEY = 'AIzaSyA3zZhJeyXx_WOVBGhXaHkkHHov5qyBiEs'; // Store your API key securely

const CHANNEL_ID = 'UCTJW4PEAKeGAnNZXRGIn0og'; // Replace with your YouTube Channel ID

export const fetchLatestVideo = async () => {
  try {
    // Check if a live video is active
    const liveResponse = await axios.get(
      `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${CHANNEL_ID}&eventType=live&type=video&key=${YOUTUBE_API_KEY}`
    );

    if (liveResponse.data.items.length > 0) {
      // Live stream found, return the live video ID
      return liveResponse.data.items[0].id.videoId;
    }

    // If no live stream, get the latest uploaded video
    const latestVideoResponse = await axios.get(
      `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${CHANNEL_ID}&order=date&type=video&key=${YOUTUBE_API_KEY}`
    );

    return latestVideoResponse.data.items[0].id.videoId; // Return the latest video ID
  } catch (error) {
    console.error('Error fetching video data:', error);
    return null;
  }
};