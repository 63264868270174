import React from 'react';
import './Exclusive.css'; // Add custom styling for the Exclusive page
import exclusiveImage from '../assets/exlcusivebanne.png'; // Image for the exclusives
import Header from '../components/Header';
import Footer from '../components/Footer';

const Exclusive = () => {
  return (
    <>
      <Header /> {/* Include Header */}
      <div className="exclusive-container">
        <header className="exclusive-header">
          <img src={exclusiveImage} alt="Exclusive" className="exclusive-image" />
          <h1>Exclusive Offers and Promotions</h1>
          <p>Discover special products, packages, and exclusive content curated just for you!</p>
        </header>

        <section className="exclusive-content">
          <h2>Exclusive Packages</h2>
          <div className="exclusive-item">
            <h3>Diabetic & Women's Health Package</h3>
            <p>Explore special health packages designed to address diabetes and women's health issues, available through Live Well.</p>
            <a href="/live-well" className="learn-more-button">Learn More</a>
          </div>

          <div className="exclusive-item">
            <h3>Goshala Products</h3>
            <p>Check out natural and sustainable Goshala products from Pachila, promoting eco-friendly and healthy living.</p>
            <a href="/shop" className="shop-now-button">Shop Now</a>
          </div>
        
        </section>
      </div>
      <Footer /> {/* Include Footer */}
    </>
  );
};

export default Exclusive;
